import { useParams } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { Duration } from 'luxon';
import { TypoHeading2 } from '@/components/Typography.tsx';
import TIMER_SUBSCRIPTION from '@/graphql/subscriptions/timer.graphql';

interface TimerProps {
  duration?: number;
  elapsed?: number;
  onComplete: () => void;
}

interface TimerContainerProps extends TimerProps {
  readOnly?: boolean;
}

const TestTimerContainer = ({ duration = 0, elapsed = 0, readOnly, onComplete }: TimerContainerProps) => {
  if (readOnly) return <TimerValue readOnly seconds={elapsed} />;
  return <TestTimer duration={duration} elapsed={elapsed} onComplete={onComplete} />;
};

const TestTimer = ({ duration = 0, elapsed = 0, onComplete }: TimerProps) => {
  const { examId } = useParams();
  const authToken = localStorage.getItem('authToken');

  const { data, loading } = useSubscription(
    TIMER_SUBSCRIPTION,
    {
      variables: { authToken, examId: Number(examId) },
    },
  );

  const seconds = useMemo(() => {
    const timerSeconds = data?.timer ?? elapsed;
    return duration - timerSeconds;
  }, [data?.timer, duration, elapsed]);

  useEffect(() => {
    if (!loading && seconds <= 0) onComplete();
  }, [loading, seconds, onComplete]);

  if (loading) return null;

  return <TimerValue seconds={seconds} />;
};

const TimerValue = ({ readOnly, seconds }: { readOnly?: boolean, seconds: number }) => {
  const minutes = seconds > 0 ? Duration.fromObject({ seconds }).toFormat('mm:ss') : '00:00';
  const text = readOnly ? `Completed in ${minutes}` : minutes;
  return <TypoHeading2 className="text-neutral2-text min-w-[6rem] text-center">{text}</TypoHeading2>;
};

export default TestTimerContainer;
